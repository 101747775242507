<template>
  <footer class="footer">
    <b-container class="text-center text-md-left pl-md-5">
      <b-row align-h="around">
        <b-col cols="12" md="4" class="mb-5 pl-md-5">
          <div class="header-title">Links</div>
          <!-- <b-navbar-brand to="/">
            <img class="logo-header" src="../assets/logo_barliner_white.svg" alt="Barliner Workout">
          </b-navbar-brand> -->
          <!-- <router-link
            exact exact-active-class="active-link"
            class="pt-3 content link-btn"
            :to="{ name: 'Home', hash: '#trainers' }"
          >Meet the team</router-link> -->
          <router-link exact exact-active-class="active-link" class="pt-3 content link-btn"
            :to="{ name: 'Personal' }">Personal training</router-link>
          <router-link exact exact-active-class="active-link" class="pt-3 content link-btn"
            :to="{ name: 'Inclusive' }">Inclusive policy</router-link>
          <router-link exact exact-active-class="active-link" class="pt-3 content link-btn"
            :to="{ name: 'Home', hash: '#trainers' }">Careers</router-link>
          <router-link exact exact-active-class="active-link" class="pt-3 content link-btn"
            :to="{ name: 'Privacy' }">Datenschutz</router-link>
          <router-link exact exact-active-class="active-link" class="pt-3 content link-btn"
            :to="{ name: 'Imprints' }">Impressum</router-link>
          <router-link exact exact-active-class="active-link" class="pt-3 content link-btn"
            :to="{ name: 'AGB' }">AGB</router-link>
        </b-col>
        <b-col cols="12" md="4" class="mb-5">
          <div class="header-title">Get in touch</div>
          <div class="pt-3">
            <div class="link-social-media">Contact</div>
            <router-link class="mail-info link-btn" to="/">info@barliner-workout.de</router-link>
          </div>
          <div class="pt-3">
            <div class="link-social-media mb-1">Follow us</div>
            <a class="mr-4 link-social-media" href="https://www.instagram.com/denvital/?hl=en" target="_blank">
              <font-awesome-icon :icon="['fab', 'instagram']" class="fa-icon" size="2x" />
            </a>
            <a class="mr-4 link-social-media" href="https://www.facebook.com/Barliner.Workout" target="_blank">
              <font-awesome-icon :icon="['fab', 'facebook']" class="fa-icon" size="2x" />
            </a>
            <a class="mr-4 link-social-media" href="https://www.youtube.com/user/BarlinerWorkout" target="_blank">
              <font-awesome-icon :icon="['fab', 'youtube']" class="fa-icon" size="2x" />
            </a>
            <!-- <a class="mr-4 link-social-media" href="https://twitter.com/barlinerworkout" target="_blank">
              <font-awesome-icon :icon="['fab', 'twitter']" class="fa-icon" size="2x" />
            </a> -->
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <div class="header-title">
            {{ $t("footer.buttons.title") }}
          </div>
          <!-- <b-button squared variant="outline-secondary"
            class="mt-3 btn-session hover__virtual"
            :to="{ name: 'LivestreamClasses' }"
          >
            {{ $t('footer.buttons.bookLivestream') }}
          </b-button> -->
          <!-- <br/> -->
          <b-button squared variant="outline-secondary" class="mt-3 btn-session hover__outdoor"
            :to="{ name: 'GroupTraining' }">
            {{ $t("footer.buttons.bookGym") }}
          </b-button>
          <b-button squared variant="outline-secondary" class="mt-3 btn-session hover__personal"
            :to="{ name: 'Personal' }">
            {{ $t("footer.buttons.bookPersonal") }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <cookie-law theme="base" class="justify-content-center" buttonClass="button-consent"
      :buttonText="$t('footer.cookie.buttonConsent')">
      <div slot="message" class="cookie-consent">
        {{ $t("footer.cookie.message") }}
        <router-link to="/privacy">{{
          $t("footer.cookie.buttonPrivacy")
        }}</router-link>
      </div>
    </cookie-law>
  </footer>
</template>

<script lang="ts">
import Vue from 'vue'
import CookieLaw from 'vue-cookie-law'
export default Vue.extend({
  name: 'Footer',
  components: { CookieLaw }
})
</script>

<style lang="scss" scoped>
.footer {
  bottom: 0;
  background-color: $primary;
  color: $white;
  padding-top: 5rem;
  padding-bottom: 6rem;

  .header-title {
    letter-spacing: 2.25px;
    font-size: 24px;
    line-height: 1.42;
  }

  .content {
    font-size: 12px;
    line-height: 1.83;
    letter-spacing: 1.5px;
    color: $white;
    display: block;
  }

  .link-btn:hover {
    text-decoration: none;
    color: $error;
  }

  .mail-info {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.88px;
    color: $white;
  }

  .link-social-media {
    color: $white;
    font-size: 12px;
    line-height: 1.83;
    letter-spacing: 1.5px;
  }

  .btn-session {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 12px;
    line-height: 1.83;
    letter-spacing: 1.5px;
    width: 235px;

    @include breakpoint(xs) {
      // background-color: yellow;
    }

    @include breakpoint(sm) {
      // background-color: tomato;
    }

    @include breakpoint(md) {
      // background-color: greenyellow;
    }
  }

  .logo-header {
    width: auto;
    height: 40px;
  }

  .Cookie--base {
    background: rgba(77, 77, 77, 0.8);
    color: $secondary;

    .cookie-consent {
      @extend .p-bw;

      a {
        color: $virtual;
        text-decoration: underline;
      }
    }
  }
}
</style>
